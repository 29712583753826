#content_container {
  background: linear-gradient(to bottom, #fef8e961 2%, white 98%) !important;
}
.zoom-meeting {
  padding-top: 100px;
  min-height: 60vh;
}
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 20px 10px 0;
}

main button:hover {
  background-color: #2681f2;
}
